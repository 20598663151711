import {GET_BASIC_PASS_SELECTED, GET_CUSTOM_FORM_DATA_FOR_ALIAS} from '../getterTypes';
import {BASIC_PASS_FIELD_NAME, CUSTOM_FORM_ALIASES} from '@/const';

export default {
  [GET_CUSTOM_FORM_DATA_FOR_ALIAS]: (state) => (alias) => {
    return alias && state.customForm[alias] || state.customForm;
  },
  [GET_BASIC_PASS_SELECTED]: (state) => {
    const form = state.customForm[CUSTOM_FORM_ALIASES.PASSES]?.find(
        ({name}) => name === BASIC_PASS_FIELD_NAME
    );
    return form ? Boolean(Number(form?.value)) : true;
  },
};
