import Vue from 'vue';
import axios from 'axios';
import _ from 'lodash';
import {API, ERROR, ERROR_CODE} from '../../const';
import requestMiddleware from '../requestMiddleware';
import {apiKeyErrorEvent} from '@/utils/helpers';
import {NO_API_KEY_URLS} from '@/urls';

const BASE_URL = API.API_HOST;
const DEFAULT_RETRY_DELAY = 1000;
const DEFAULT_CONFIG = {
  retryCount: 1,
};
const DEFAULT_REQUEST_CONFIG = {
  [ERROR_CODE.THROTTLING]: {
    globalError: true,
  },
};

const requestTransformFunction = (data) => {
  if (data instanceof FormData) return data;
  const fd = new FormData();
  _.forIn(data, (value, key) => {
    if (Array.isArray(value)) {
      value.forEach((v) => fd.append(`${key}[]`, v));
    } else {
      fd.append(key, value);
    }
  });
  return fd;
};

const isCrashedErrorCode = (err) => {
  const customConfig = err?.config?.customConfig;
  const status = err.response.status;

  return customConfig?.crashCodes?.some((codeReg) => {
    const reg = new RegExp(codeReg);
    return reg.test(status);
  });
};
const isAllowErrorCode = (err) => {
  return err.config?.customConfig?.allowedCodes?.includes(err.response.status);
};

axios.interceptors.request.use(async (requestConfig) => {
  return await requestMiddleware.checkApiKey(requestConfig);
});
axios.interceptors.response.use((response) => {
  if (response.status !== 200) {
    return Promise.reject(response.data);
  }
  return response;
}, async (err) => {
  if (
    err.code === ERROR.CANCELED ||
    err.response?.status === ERROR_CODE.THROTTLING
  ) {
    return Promise.reject(err);
  }
  const {
    config,
    response,
  } = err;
  const channelKeyError = Boolean(response?.data?.error?.errors?.channel_key);
  const apiKeyError = Boolean(response?.data?.error?.errors?.api_key);
  const isKeyErrorForNoConfig = (apiKeyError || channelKeyError) && !config.customConfig;
  const delayRetryRequest = new Promise((resolve) => {
    setTimeout(resolve, config.customConfig?.retryDelay || DEFAULT_RETRY_DELAY);
  });

  if (!isAllowErrorCode(err) && err?.config?.customConfig?.retryCount || isKeyErrorForNoConfig) {
    if (channelKeyError || apiKeyError) {
      channelKeyError && Vue.$storage.pull('channel_key');
      Vue.$storage.pull('api_key');
    }
    return delayRetryRequest.then(
        async () => {
          let newConfig = config;
          if (!Vue.$storage.get('api_key')) {
            await requestMiddleware.getApiKey();
            newConfig = requestMiddleware.updateApiKey(newConfig);
          }

          if (isKeyErrorForNoConfig) {
            newConfig.customConfig = {...DEFAULT_CONFIG};
          }

          newConfig.customConfig.retryCount = newConfig.customConfig.retryCount - 1;
          return axios({
            ...newConfig,
            transformRequest: [requestTransformFunction],
          });
        },
    );
  } else {
    return Promise.reject(err);
  }
});

/**
 * Base request method
 * @param {string} url
 * @param {object} params
 * @param {string} method
 * @param {string} baseUrl
 * @param {object} token
 * @param {object} config
 * @return {Promise<AxiosResponse<any>>}
 */
async function request({
  url,
  params = {},
  method = 'post',
  baseUrl = BASE_URL,
  token,
  config,
}) {
  const {
    params: preparedParams,
    url: preparedUrl,
  } = requestMiddleware.prepareParams({
    params,
    method,
    url,
  });

  return axios({
    url: preparedUrl,
    method: method,
    baseURL: baseUrl,
    timeout: 30000,
    transformRequest: [requestTransformFunction],
    data: preparedParams,
    signal: token,
    customConfig: config,
  }).then((res) => {
    !NO_API_KEY_URLS.includes(url) && apiKeyErrorEvent(false, preparedUrl);
    return res.data;
  }).catch((err) => {
    if (err.code === ERROR.CANCELED) {
      return Promise.reject(err);
    }

    const status = err.response.status;
    const defaultConfig = DEFAULT_REQUEST_CONFIG[status];

    if (defaultConfig?.globalError) {
      apiKeyErrorEvent(true, preparedUrl);
      return Promise.reject(err.response.data);
    }

    if (err.config?.customConfig?.globalError) {
      if (isCrashedErrorCode(err) && !isAllowErrorCode(err)) {
        apiKeyErrorEvent(true, preparedUrl);
      }
      return Promise.reject(err.response.data);
    }

    if (err.response?.data?.error?.errors?.channel_key) {
      Vue.$storage.pull('api_key');
      Vue.$storage.pull('channel_key');
      apiKeyErrorEvent(true, preparedUrl);
    }
    if (err.response?.data?.error?.errors?.api_key) {
      Vue.$storage.pull('api_key');
      apiKeyErrorEvent(true, preparedUrl);
    }
    return Promise.reject(err.response.data);
  });
}

export default requestMiddleware.checkDuplication(request);
