export const PAGES_NAMES = {
  LOGIN: 'login',
  REGISTRATION: 'registration',
  VERIFY_NOTICE: 'verify-notice',
  VERIFY_EMAIL: 'verify',
  PASSWORD_RESET_REQUEST: 'password-reset-request',
  PASSWORD_RESET: 'password-reset',
  PROFILE: 'profile',
  PROFILE_EDIT: 'profile-edit',
  CONTACTS: 'contacts',
  BUY: 'buy',
  SELECT_PACKAGE: 'select-package',
  FAQ: 'faq',
  SIMULATE: 'simulate',
  ERROR: 'error-page',
  ABOUT: 'about',
  HOME: 'home',
  PACKAGES: 'packages',
  VOD_CLIPS: 'vod-clips',
  WATCH: 'watch',
  SHARE: 'share',
  EMBEDDED_WATCH: 'embedded-watch',
  CLIPS_WATCH: 'clips-watch',
  VOD: 'onDemand',
  UPCOMING: 'upcoming',
  ALL_EVENTS: 'All',
  EVENTS: 'events',
  TRANSACTIONS: 'transactions',
  ORDERS_LIST: 'orders list',
  ACTIVE_ORDERS: 'active orders',
  CLIPS: 'clips',
  LIVE: 'live',
};

export const LOGIN = {
  name: PAGES_NAMES.LOGIN,
  path: '/login',
};
export const REGISTRATION = {
  name: PAGES_NAMES.REGISTRATION,
  path: '/registration',
};
export const VERIFY_NOTICE = {
  name: PAGES_NAMES.VERIFY_NOTICE,
  path: '/verify-notice',
};
export const VERIFY_EMAIL = {
  name: PAGES_NAMES.VERIFY_EMAIL,
  path: '/verify-email',
};
export const PASSWORD_RESET_REQUEST = {
  name: PAGES_NAMES.PASSWORD_RESET_REQUEST,
  path: '/password-reset-request',
};
export const PASSWORD_RESET = {
  name: PAGES_NAMES.PASSWORD_RESET,
  path: '/password-reset',
};
export const PROFILE = {
  name: PAGES_NAMES.PROFILE,
  path: '/profile',
};
export const PROFILE_EDIT = {
  name: PAGES_NAMES.PROFILE_EDIT,
  path: '/profile/edit',
};
export const CONTACTS = {
  name: PAGES_NAMES.CONTACTS,
  path: '/contacts',
};
export const BUY = {
  name: PAGES_NAMES.BUY,
  path: '/buy',
};
export const SELECT_PACKAGE = {
  name: PAGES_NAMES.SELECT_PACKAGE,
  path: '/select-package/:id(\\d+)',
};
export const FAQ = {
  name: PAGES_NAMES.FAQ,
  path: '/faq',
};
export const SIMULATE = {
  name: PAGES_NAMES.SIMULATE,
  path: '/simulate',
};
export const ERROR = {
  name: PAGES_NAMES.ERROR,
  path: '/404',
};
export const ABOUT = {
  name: PAGES_NAMES.ABOUT,
  path: '/about',
};
export const HOME = {
  path: '/',
  name: PAGES_NAMES.HOME,
};
export const PACKAGES = {
  name: PAGES_NAMES.PACKAGES,
  path: '/packages',
};
export const VOD_CLIPS = {
  name: PAGES_NAMES.VOD_CLIPS,
  path: '/vod_library',
};
export const WATCH = {
  name: PAGES_NAMES.WATCH,
  path: '/watch/:id(\\d+)',
};
export const EMBEDDED_WATCH = {
  name: PAGES_NAMES.EMBEDDED_WATCH,
  path: '/watch_embed/:id(\\d+)',
};
export const CLIPS_WATCH = {
  name: PAGES_NAMES.CLIPS_WATCH,
  path: '/watch_library/:id(\\d+)',
};
export const SHARE = {
  name: PAGES_NAMES.SHARE,
  path: '/share/:id(\\d+)/:uuid',
};
export const VOD = {
  name: PAGES_NAMES.EVENTS,
  path: '/vod',
};
export const UPCOMING = {
  name: PAGES_NAMES.EVENTS,
  path: '/upcoming',
};
export const LIVE = {
  name: PAGES_NAMES.EVENTS,
  path: '/live',
};
export const ALL_EVENTS = {
  name: PAGES_NAMES.EVENTS,
  path: '/schedule',
};
export const ORDER_LIST = {
  name: PAGES_NAMES.ORDERS_LIST,
  path: '',
};
export const ACTIVE_ORDERS = {
  name: PAGES_NAMES.ACTIVE_ORDERS,
  path: '',
};
export const TRANSACTIONS = {
  name: PAGES_NAMES.TRANSACTIONS,
  path: 'transactions',
};
export const CLIPS = {
  name: PAGES_NAMES.CLIPS,
  path: 'clips',
};
export const POLICY = {
  path: '/privacy_policy',
};
export const TERMS = {
  path: '/terms_conditions',
};
export const PASSES_TOP_BANNER = {
  path: 'passes',
};

export const PAGES_PATHS = {
  LOGIN,
  REGISTRATION,
  VERIFY_NOTICE,
  VERIFY_EMAIL,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET,
  PROFILE,
  PROFILE_EDIT,
  CONTACTS,
  BUY,
  SELECT_PACKAGE,
  SHARE,
  FAQ,
  SIMULATE,
  ERROR,
  ABOUT,
  HOME,
  PACKAGES,
  VOD_CLIPS,
  WATCH,
  EMBEDDED_WATCH,
  CLIPS_WATCH,
  VOD,
  UPCOMING,
  LIVE,
  ALL_EVENTS,
  ORDER_LIST,
  ACTIVE_ORDERS,
  TRANSACTIONS,
  CLIPS,
};
