import {
  GET_PASSES_WITH_ACCESSES,
  PREPARE_SELECTED_PASSES,
} from '@/store/getterTypes';
import {ORDER_ACTIVITY_STATUS, ORDER_STATUS, ORDER_TYPE} from '@/const';
import participant from '@/store/participant/participant';

export default {
  [GET_PASSES_WITH_ACCESSES]: (state, getters) => {
    return getters[PREPARE_SELECTED_PASSES]({
      packages: state.packagesList,
      subscriptions: state.subscriptionsList,
    });
  },
  [PREPARE_SELECTED_PASSES]: (state) => ({
    packages = [],
    subscriptions = [],
    events = [],
  }) => {
    // currently expected for 1 category and not expected team-pass type;
    const packagesList = Object.fromEntries(
        packages.map((item) => [item.id, {
          ...item,
          purchased: false,
          passType: ORDER_TYPE.package,
        }]));
    const subscriptionsList = Object.fromEntries(
        subscriptions.map((item) => [item.id, {
          ...item,
          purchased: false,
          passType: ORDER_TYPE.subscription,
        }]));
    const eventsList = Object.fromEntries(
        events.map((item) => [item.id, {
          ...item,
          purchased: false,
          passType: ORDER_TYPE.event,
        }]));
    const orders = [...state.ordersList];

    orders.forEach((order) => {
      if (order.status === ORDER_STATUS.paid &&
        order.activity_status === ORDER_ACTIVITY_STATUS.active
      ) {
        if (order.type === ORDER_TYPE.subscription) {
          subscriptionsList[order.product.id] &&
          (subscriptionsList[order.product.id].purchased = true);
        } else if (order.type === ORDER_TYPE.package) {
          const currentPackage = packagesList[order.product.id];
          if (!currentPackage) return;
          const categoryId = order.product.event_category_id;
          const participantId = order.product.participant_id;

          if (!currentPackage.disabledCategories) {
            currentPackage.disabledCategories = [];
          }
          categoryId && currentPackage.disabledCategories.push(categoryId);

          if (!currentPackage.disabledParticipants) {
            currentPackage.disabledParticipants = [];
          }
          participantId && currentPackage.disabledParticipants.push(participantId);

          if (currentPackage.is_team_pass) {
            currentPackage.purchased = participant.state.participantsList.length ===
              currentPackage.disabledParticipants.length;
          } else {
            currentPackage.purchased = Object.keys(currentPackage.event_categories).length ===
              currentPackage.disabledCategories.length;
          }
        } else if (order.type === ORDER_TYPE.event) {
          eventsList[order.product.id] &&
          (eventsList[order.product.id].purchased = true);
        }
      }
    });
    return [
      ...subscriptions.map(({id}) => subscriptionsList[id]),
      ...packages.map(({id}) => packagesList[id]),
      ...events.map(({id}) => eventsList[id]),
    ];
  },
};
