import api from '../../api/api';
import {
  GET_PARTICIPANT_GROUP_LIST,
  GET_ALL_PARTICIPANTS, GET_PARTICIPANTS_PROPS,
} from '../actionTypes';
import {
  UPDATE_PARTICIPANTS_LIST,
  UPDATE_PARTICIPANT_GROUP_LIST,
  UPDATE_GROUP_MENU,
  UPDATE_PARTICIPANTS_PROPS,
} from '../mutationTypes';

export default {
  [GET_PARTICIPANT_GROUP_LIST]: async ({commit}) => {
    const groups = await api.participantApi.get_participant_groups();
    if (groups.data) {
      commit(UPDATE_PARTICIPANT_GROUP_LIST, groups.data);
      commit(UPDATE_GROUP_MENU, groups.data);
    }
  },
  [GET_ALL_PARTICIPANTS]: async ({commit}) => {
    const response = await api.participantApi.get_participants({
      no_limit: 1,
      });
    commit(UPDATE_PARTICIPANTS_LIST, response?.data);
  },
  [GET_PARTICIPANTS_PROPS]: async ({commit}) => {
    commit(UPDATE_PARTICIPANTS_PROPS,
        await api.participantApi.get_participant_group_props().then(({data}) => data),
    );
  },
};
